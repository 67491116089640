const colorList = [
    "#ffcccc",
    "#ccccff",
    "#e6ccff",
    "#ccf2ff",
    "#ccffe6",
    "#eeffcc",
    "#ffffcc",
    "#ffebcc",
    "#f5d6eb",
    "#f5ccff"
]

export default colorList;